<template>
  <div>
    <CrudRead
      v-bind="this.$store.state.stone_model.bonus_point"
      @title-bar-custom-btn-click="$_titleBarCustomBtnClick($event)"
    ></CrudRead>
    <WsPopupAlert
      ref="bonusDeletePopup"
      :title="$t('確定要刪除此紅利點數嗎？')"
      @submit="$_bonusDelete($event)"
      :submitBtnText="$t('確定')"
    ></WsPopupAlert>
  </div>
</template>

<script>
export default {
  methods: {
    async $_titleBarCustomBtnClick($event) {
      if ($event == "update-bonus") {
        this.$router.push(`/bonus_point/${this._id}/update`);
      }
      if ($event == "delete-bonus") {
        this.$_bonusDeletePopupOpen();
      }
    },
    $_bonusDeletePopupOpen(data) {
      this.$refs.bonusDeletePopup.open(data);
    },
    $_bonusDeletePopupClose() {
      this.$refs.bonusDeletePopup.close();
    },
    async $_bonusDelete() {
      try {
        this.$_bonusDeletePopupClose();
        await this.$axios.delete(`/shop_campaign/${this._id}`);
        await this.$router.push(`/bonus_point`);
      } catch (err) {
        alert(err);
      }
    },
  },
  computed: {
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>